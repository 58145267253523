import React from "react"
import { Carousel } from "react-bootstrap"

import "./header.scss"

const fileBucket = process.env.FILE_BUCKET

const Header = () => {
  return (
    <>
      <Carousel controls={false} fade={true} indicators={false}>
        <Carousel.Item>
          <img
            className="header-background"
            src={`${fileBucket}/headers/header1_2780.jpg`}
            alt=""
            srcSet={`
              ${fileBucket}/headers/header1_992.jpg 992w,
              ${fileBucket}/headers/header1_1152.jpg 1152w,
              ${fileBucket}/headers/header1_1536.jpg 1536w,
              ${fileBucket}/headers/header1_1984.jpg 1984w,
              ${fileBucket}/headers/header1_2400.jpg 2400w,
              ${fileBucket}/headers/header1_2780.jpg 2780w,
            `}
            role="presentation"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="header-background"
            src={`${fileBucket}/headers/header2_2780.jpg`}
            alt=""
            srcSet={`
              ${fileBucket}/headers/header2_992.jpg 992w,
              ${fileBucket}/headers/header2_1152.jpg 1152w,
              ${fileBucket}/headers/header2_1536.jpg 1536w,
              ${fileBucket}/headers/header2_1984.jpg 1984w,
              ${fileBucket}/headers/header2_2400.jpg 2400w,
              ${fileBucket}/headers/header2_2780.jpg 2780w,
            `}
            role="presentation"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="header-background"
            src={`${fileBucket}/headers/header3_2780.jpg`}
            alt=""
            srcSet={`
              ${fileBucket}/headers/header3_992.jpg 992w,
              ${fileBucket}/headers/header3_1152.jpg 1152w,
              ${fileBucket}/headers/header3_1536.jpg 1536w,
              ${fileBucket}/headers/header3_1984.jpg 1984w,
              ${fileBucket}/headers/header3_2400.jpg 2400w,
              ${fileBucket}/headers/header3_2780.jpg 2780w,
            `}
            role="presentation"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="header-background"
            src={`${fileBucket}/headers/header4_2780.jpg`}
            alt=""
            srcSet={`
              ${fileBucket}/headers/header4_992.jpg 992w,
              ${fileBucket}/headers/header4_1152.jpg 1152w,
              ${fileBucket}/headers/header4_1536.jpg 1536w,
              ${fileBucket}/headers/header4_1984.jpg 1984w,
              ${fileBucket}/headers/header4_2400.jpg 2400w,
              ${fileBucket}/headers/header4_2780.jpg 2780w,
            `}
            role="presentation"
          />
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default Header
