import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import logo from "../../images/brand.jpg"

import "./navi.scss"

const fileBucket = process.env.FILE_BUCKET

const Navi = (props) => {
  const activeItem = (path) => {
    if (props.location.pathname === path) {
      return "nav-item active"
    } else {
      return "nav-item"
    }
  }

  return (
    <Navbar expand="md" bg="light-trans">
      <div className="container main-nav-container">
        <Navbar.Brand href="/">
          <img alt="" src={logo} className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="main-nav">
          <ul className="navbar-nav nav-fill">
            <li className={activeItem("/")}>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <NavDropdown title="Services">
              <Link to="/services/travel-club" className="dropdown-item">
                Travel Club
              </Link>
              <Link to="/services/day-tours" className="dropdown-item">
                Day Tours
              </Link>
              <Link to="/services/extended-tours" className="dropdown-item">
                Extended Tours
              </Link>
              <Link to="/services/tfnsw" className="dropdown-item">
                TFNSW SSTS
              </Link>
              <a
                href={`${fileBucket}/timetable.pdf`}
                className="dropdown-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                Service Timetable
              </a>
            </NavDropdown>
            <li className={activeItem("/fleet")}>
              <Link to="/fleet" className="nav-link">
                Fleet
              </Link>
            </li>
            <li className={activeItem("/quote")}>
              <Link to="/quote" className="nav-link">
                Quote
              </Link>
            </li>
            <li className={activeItem("/contact")}>
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navi
