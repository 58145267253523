import React from "react"
import { Link } from "gatsby"

import "./index.scss"

const CovidNotice = () => (
  <div className="row">
    <div className="col text-center covid-notice">
      <span className="covid-notice-text">Stuarts Coaches and COVID-19 - </span>
      <Link to="/covid-19">Learn More</Link>
    </div>
  </div>
)

export default CovidNotice
