import React from "react"
import { Link } from "gatsby"

import "./footer.scss"

const fileBucket = process.env.FILE_BUCKET

const Footer = () => (
  <>
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="nav">
              <Link to="/services/travel-club" className="nav-link">
                Travel Club
              </Link>
              <Link to="/services/day-tours" className="nav-link">
                Day Tours
              </Link>
              <Link to="/services/extended-tours" className="nav-link">
                Extended Tours
              </Link>
              <Link to="/services/tfnsw" className="nav-link">
                TFNSW SSTS
              </Link>
              <a
                href={`${fileBucket}/timetable.pdf`}
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Service Timetable
              </a>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-base">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 footer-base-left">
            <nav className="nav">
              <Link to="/" className="nav-link">
                Stuarts Coaches 2020
              </Link>
              <Link to="/terms-of-use" className="nav-link">
                Terms of Use
              </Link>
              <Link to="/privacy-policy" className="nav-link">
                Privacy Policy
              </Link>
            </nav>
          </div>
          <div className="col-12 col-lg-6 footer-base-right">
            <span>Site by </span>
            <a
              href="https://www.dssdigital.net/?utm_source=website&utm_medium=tag&utm_campaign=stuarts-coaches"
              target="_blank"
              rel="noopener noreferrer"
            >
              DSS Digital
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Footer
