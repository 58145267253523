import React from "react"

import Icon from "../Icon"
import Button from "../Button"

import "./topbar.scss"

const TopBar = () => {
  return (
    <div className="header-top">
      <div className="desktop">
        <div className="container">
          <div className="row">
            <div className="col-10 text-left">
              <span>02 4421 0332 | 339 Greenwell Point Road Worrigee NSW</span>
            </div>
            <div className="col-2 text-right">
              <a
                href="https://www.facebook.com/stuartscoaches/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon prefix="fab" name="facebook-f" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile">
        <div className="container">
          <div className="row">
            <div className="col-12 text-left">
              <a href="https://www.google.com/maps/place/Stuart's+Coaches+PTY+LTD/@-34.8883516,150.6389442,17z/data=!3m1!4b1!4m5!3m4!1s0x6b1481da8c5f122d:0xd3aacdff8face0d3!8m2!3d-34.888356!4d150.6411329">
                <Button
                  customClass="btn-icon-left stu-primary"
                  icon="location-arrow"
                  iconPosition="left"
                >
                  Location
                </Button>
              </a>
              <a href="tel:0244210332">
                <Button
                  customClass="btn-margin-left btn-icon-left stu-primary"
                  icon="phone-alt"
                  iconPosition="left"
                >
                  Phone
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
