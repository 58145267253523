import React from "react"
import PropTypes from "prop-types"

import CovidNotice from "../Content/CovidNotice"
import TopBar from "./topbar"
import Navi from "./navi"
import Header from "./header"
import Footer from "./footer"

import "./index.scss"

const Layout = ({ children, location }) => (
  <>
    <CovidNotice />
    <TopBar />
    <Navi location={location} />
    <Header />
    <main className="container">{children}</main>
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
